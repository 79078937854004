.container {
    padding: 0;

    h1 {
        font-size: 4rem;
    }

    h2 {
        font-size: 2.875rem;
    }

    p {
        font-size: 22px;
        line-height: 32px;
    }
}